import Nav from "../../../components/nav/Nav";

export default function ChatIndex() {
  return (
    <>
      <Nav />
      <h1>Chat Index</h1>
    </>
  );
}
